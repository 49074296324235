/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {getScrollTriggers, setScrollTrigger} from "utils/scrolltriggers";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    strong: "strong",
    em: "em"
  }, _provideComponents(), props.components), {ResourcePanel, Pin, InfoNugget, InfoItem, Headline, Obl, CareInstructions, CareItem} = _components;
  if (!CareInstructions) _missingMdxReference("CareInstructions", true);
  if (!CareItem) _missingMdxReference("CareItem", true);
  if (!Headline) _missingMdxReference("Headline", true);
  if (!InfoItem) _missingMdxReference("InfoItem", true);
  if (!InfoNugget) _missingMdxReference("InfoNugget", true);
  if (!Obl) _missingMdxReference("Obl", true);
  if (!Pin) _missingMdxReference("Pin", true);
  if (!ResourcePanel) _missingMdxReference("ResourcePanel", true);
  return React.createElement(React.Fragment, null, React.createElement(ResourcePanel, {
    id: "figure01",
    image: "figure01",
    imageAlt: "lorem ipsum",
    roundTopCorners: true,
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure01",
    type: "start",
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Melanoma is the most dangerous form of skin cancer. It is often diagnosed in people with Black skin when it is too late to cure. Though it can appear on any skin surface, common locations for melanoma on Black people are on non-sun-exposed skin including the:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "bottom of the foot"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "lower legs"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "palms"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "under a fingernail or toenail"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, {
    noBorder: true
  }, "groin"), "\n"), "\n")), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Though melanoma is very rare in people with darker skin, when it does occur it is often diagnosed too late to cure. ", React.createElement(_components.strong, null, "It is best to treat melanoma in its earliest stages."))), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Did You Know?"), React.createElement(_components.p, null, "According to the American Academy of Dermatology, ", React.createElement(_components.strong, null, "25 percent"), " of melanomas in Black people are diagnosed ", React.createElement(_components.em, null, "after the cancer has already spread to surrounding lymph nodes"), ". Receiving a diagnosis at a later stage can make skin cancer much harder to treat. It can also negatively impact outlook."), React.createElement(_components.p, null, "Follow the below tips to increase your chances of spotting skin cancer early, when it's most treatable. If you notice any new spots on your skin, spots that are different from others, or spots that are changing, itching or bleeding, contact a board-certified dermatologist.")), React.createElement(Pin, {
    id: "figure01",
    type: "end",
    height: 1,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure02",
    image: "figure02",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers,
    slug: props.data.mdx.frontmatter.slug
  }, React.createElement(Pin, {
    id: "figure02",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "What are the ABCDEs of Melanoma?"), React.createElement(_components.p, null, "Melanoma is a deadly skin cancer that is rare in Blacks but is often diagnosed too late to cure."), React.createElement(_components.p, null, "This mole shows most of the ABCDEs of skin cancer."), React.createElement(_components.p, null, "The ABCDEs of Melanoma are:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "Asymmetry"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "Border"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "Color"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "Diameter"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, {
    noBorder: true
  }, "Evolving"), "\n"), "\n")), React.createElement(Pin, {
    id: "figure02",
    type: "end",
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure03",
    image: "figure03",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure03",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Assymetry"), React.createElement(_components.p, null, "Both halves of the mole do not match.")), React.createElement(Pin, {
    id: "figure03",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure04",
    image: "figure04",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure04",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Borders"), React.createElement(_components.p, null, "The mole has very uneven or blurry borders.")), React.createElement(Pin, {
    id: "figure04",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure05",
    image: "figure05",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure05",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Color"), React.createElement(_components.p, null, "The mole has three or more colors.")), React.createElement(Pin, {
    id: "figure05",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure06",
    image: "figure06",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure06",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Diameter"), React.createElement(_components.p, null, "The diameter of the mole is larger than a No. 2 pencil eraser (6mm).")), React.createElement(Pin, {
    id: "figure06",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure07",
    image: "figure07",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure07",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Evolution"), React.createElement(_components.p, null, "The mole changes considerably over time in:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "size"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "color"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "shape"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, {
    noBorder: true
  }, "texture"), "\n"), "\n")), React.createElement(Pin, {
    id: "figure07",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "video01",
    video: "XY9-nvd4hVQ",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "video01",
    type: "start",
    height: 300,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Hear Dr. Chesahna Kindred of the ", React.createElement(Obl, {
    to: "https://kindredhairandskin.com/",
    title: "Dr. Kindred's Practice"
  }, "Kindred Hair and Skin Center"), " share more how you you can watch out for Melanoma.")), React.createElement(Pin, {
    id: "video01",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(CareInstructions, null, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "During your monthly self-skin cancer checks, remember to use a ", React.createElement(Obl, {
    to: "https://amzn.to/3GAPLhu",
    title: "Amazon Affiliate Link"
  }, "long mirror"), " to check your back, the back of your legs, your underarms and other hard to see areas."), "\n"), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
